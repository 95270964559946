import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import pic04 from '../assets/images/img.png'
import Header from '../components/Header'
import { Link } from 'gatsby'

class Bestattungen extends React.Component {
  render() {

    return (
      <Layout>
        <Helmet title="Arten von Bestattungen | Bestattungshaus Bartko" />

        <Header isChildPage={true} />
        <div id="main">
          <section id="content" className="main">
            <span className="image main"><img src={pic04} alt="" /></span>

            <h1>Arten von Bestattungen</h1>
            <p>Sie können zwischen Erd-, Feuer-, und Seebestattungen wählen. Über die verschiedenen Bestattungsmöglichkeiten geben wir Ihnen gern Auskunft.<br />
              Wir bieten Ihnen Bestattungen von schlicht bis exklusiv.</p>

            <p>Wir führen durch:</p>
            <ul>
              <li>Erdbestattung</li>
              <li>Feuerbestattung</li>
              <li>Seebestattung</li>
              <li>Baumbestattung</li>
              <li>FriedWald</li>
              <li>Sozialbestattung</li>
            </ul>
            <p>Und sind gern bei der Organisation und Durchführung behilflich</p>

            <center>
              <Link to="/" className="button">
                Zurück zur Übersicht
              </Link>
            </center>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Bestattungen
